.Ellipse-1 {
  position: absolute;
  width: 45vw;
  height: 3vh;
  border-radius: 50%;
  -webkit-filter: blur(15px);
  filter: blur(15px);
  background-color: #aaa4aa;
  right: 7vw;
}

.ut-big-title {
  font-family: Rubik;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  @apply text-darkslateblue;
}

.ut-sub-title {
  font-family: Rubik;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  @apply text-darkslateblue;
}
.ut-sub-note {
  font-family: Rubik;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  @apply text-darkslateblue;
}

.ut-sub-note2 {
  font-family: Rubik;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  @apply text-darkslateblue;
}

.ut-sub-title2 {
  font-family: Rubik;
  margin-top: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  @apply text-darkslateblue;
}
