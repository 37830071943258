.fpt-c-email-verified-page {
  display: flex;
  flex-direction: column;
  color: #1e4e5f !important;
  overflow: hidden !important;
  background: linear-gradient(206deg, #e0ecf8, #fdf5ec) !important;
  height: 100vh;
  padding: 20px;
  text-align: center;
}

.fpt-c-email-verified-page-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 50px;
}

.fpt-c-email-verified-page-text {
  padding: 20px;
  font-size: 1rem;
  font-weight: normal;
}
.fpt-c-email-verified-page-title {
  font-size: 1.625rem;
  font-weight: 500;
}
.fpt-c-footer-container {
  padding: 0px;
}
.fpt-c-link-p {
  margin: 1rem;
}
.fpt-c-link-text {
  margin: 0 0 1.75rem;
  padding: 1rem 1rem;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: underline;
  color: var(--dark-slate-blue);
}

.fpt-c-email-verified-page-action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 50px;
}
