.co_calculator_scene {
  width: 100%;
  max-width: 35.75rem;
  perspective: 1000px;
}

.co_calculator_card {
  position: relative;
  width: 100%;
  min-height: 400px;
  transform-style: preserve-3d;
  transition: transform 0.7s;
}

.co_calculator_card.flipped {
  transform: rotateY(180deg);
}

.co_calculator_card_content {
  position: absolute;
  width: 100%;
  border-radius: 2rem;
  overflow: hidden;
  background: white;
  backface-visibility: hidden;
}

.co_calculator_front {
  transform: rotateY(0deg);
}

.co_calculator_back {
  transform: rotateY(180deg);
}
.co_result {
  font-family: Inter;
  font-size: 2.125rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7edfb5;
}
.co_co2 {
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #7edfb5;
}
