.Gdpr-Container {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.13);
  border-radius: 36px;
  @apply lg:w-10/12 bg-white p-8 z-40 lg:max-w-[1000px] md:max-w-[700px] sm:max-w-[340px];
  @apply fixed bottom-0 mb-8 z-[1000];
}

.Gdpr-Title {
  @apply text-sm font-medium mt-4 mb-2 text-darkslateblue h-[25px];
}

.Gdpr-Lock-Image {
  @apply ml-2 -mt-0 w-[18px] h-[18px] float-left;
}

.Gdpr-Content {
  @apply text-base font-thin text-darkslateblue leading-[1.65rem];
}
.Gdpr-Content a {
  @apply no-underline;
}
.Gdpr-Checkboxes {
  @apply mt-8 mb-3 sm:mt-10 grid lg:grid-cols-3 md:grid-cols-3  sm:grid-cols-1;
}

.Gdpr-Buttons {
  @apply lg:mt-5 sm:gap-2 gap-10 justify-end sm:justify-center sm:items-center sm:w-full sm:flex-col;
}

.Gdpr-Light-Button {
  @apply p-3 rounded-lg border-2 border-darkslateblue text-tiny font-thin text-darkslateblue;
}

.Gdpr-Dark-Button {
  @apply p-3 rounded-lg border-2 border-darkslateblue text-tiny font-thin text-white bg-darkslateblue;
}

.Gdpr-Link {
  @apply font-semibold text-[#464aa6] no-underline;
  text-decoration: none !important;
  text-decoration-line: none !important;
}
