.column p {
  margin-bottom: 1rem;
}
.pp-title {
  width: 21.375rem;
  height: 2.25rem;
  font-family: Inter;
  font-size: 1.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  @apply text-darkslateblue;
}

.pp-title-date {
  opacity: 0.7;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  @apply text-darkslateblue;
}

.pp-body {
  @apply text-darkslateblue text-lg font-normal;
}
.pp-text-block-title {
  @apply font-bold text-lg;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.pp-text-block-link {
  color: #58b5b5;
  text-decoration: underline;
}
