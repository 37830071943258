.list {
  display: flex;
  flex-direction: column;
}

.list-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.list-item-number {
  font-family: Rubik;
  font-size: 1.625rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  margin-right: 1rem;
}
.list-item-text {
  font-family: Inter;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-slate-blue);
}
