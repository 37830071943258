.Buttonv2 {
  @apply sm:w-[12.25rem] w-[14.81rem] h-[3.375rem] rounded-full border border-darkslateblue font-thin cursor-pointer m-0 opacity-100;
}

.Buttonv2:disabled {
  @apply cursor-wait;
  @apply opacity-80;
}

.Light-Button {
  @apply text-darkslateblue;
}
.large-light-button {
  font-family: Inter;
  @apply w-full md:min-w-[17rem] min-h-[3.375rem] h-[3.375rem] lg:text-base mdSide:text-base  text-sm md:text-lg  font-medium mdSide:font-medium;
}
.Dark-Button {
  @apply text-almond bg-darkslateblue sm:w-full;
}
.Light-Border-Button {
  @apply text-almond border-almond;
}
.Light-No-Border-Button {
  @apply text-almond  bg-[#395e6e];
}
.Trans-Button {
  @apply text-[#395e6e] border-darkslateblue border-solid font-medium text-base;
}
.large-button {
  font-family: Inter;
  @apply min-w-[12.81rem] md:min-w-[17rem] min-h-[3.375rem] h-[3.375rem] lg:text-base mdSide:text-base  text-sm md:text-lg  font-medium mdSide:font-medium;
}
.large-text-button {
  font-family: Inter;
  @apply min-w-[12.81rem] min-h-[3.375rem] sm:min-w-[12.25rem] lg:text-base mdSide:text-base  text-lg font-normal mdSide:font-semibold;
}
.White-No-Border-Button {
  @apply text-[#395e6e] sm:pl-0;
}
.small-button {
  font-family: Inter;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  width: 8.313rem;
  height: 2.375rem;
  padding: 0.438rem 1.688rem;
  border-radius: 100px;
  background-color: #395e6e;
  @apply lg:text-base text-sm;
}
.mid-button {
  width: 10.313rem;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-slate-blue);
}

.Dark-Button img,
.Light-Button img {
  margin: auto;
  height: 20px;
}
