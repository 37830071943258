.column p {
  margin-bottom: 1rem;
}
.pp-imprint-title {
  font-family: Inter;
  font-size: 1.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  @apply text-darkslateblue;
}

.pp-body {
  @apply text-darkslateblue text-lg font-normal;
}
.pp-text-block-title {
  @apply font-bold text-lg;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.pp-text-block-link {
  color: #464aa6;
  text-decoration: underline;
}
