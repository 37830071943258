.Footer-Container {
  @apply flex bg-darkGrey w-full items-start;
}

.Footer-Container-Wrapper {
  @apply flex flex-col lg:w-full items-start justify-between gap-4 md:w-full  pt-16;
}

.Footer-Segment {
  @apply flex flex-col;
}

.Footer-Link {
  @apply text-sm font-thin;
}

.Footer-Segment-Header {
  @apply text-sm tracking-wide font-bold text-white mb-6;
}

.Footer-Copyright {
  @apply grid place-items-center mb-4 mt-6;
}

.Footer-Copyright-Text {
  @apply text-xs tracking-wide text-white mt-4;
}
.Footer-Link-Menu {
  @apply text-white  w-full;
}
