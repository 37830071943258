@import 'fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @media only screen and (min-width: 120px) and (max-width: 520px) {
    html {
      font-family: Inter, system-ui, sans-serif;
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 521px) and (max-width: 1024px) {
    html {
      font-family: Inter, system-ui, sans-serif;
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1165px) {
    html {
      font-family: Inter, system-ui, sans-serif;
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 1166px) and (max-width: 1439px) {
    html {
      font-family: Inter, system-ui, sans-serif;
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 1440px) and (max-width: 1919px) {
    html {
      font-family: Inter, system-ui, sans-serif;
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 1920px) {
    html {
      font-family: Inter, system-ui, sans-serif;
      font-size: 16px;
    }
  }
}

body {
  background-color: #fdf5ec !important;
}
* {
  scroll-behavior: smooth;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  width: 0px;
}

.page {
  position: relative;
  display: block;
  min-height: 100vh;
}

.btn {
  @apply bg-blueberry tracking-wide text-white text-base px-16 py-4 w-full rounded shadow-md;
}

.btn-empty {
  @apply border-2 border-blueberry text-blueberry text-sm px-12 py-2 w-full rounded;
}

.text-component-label {
  @apply -mt-2 mb-2 ml-1 text-darkslateblue font-light;
}

.btn-solid {
  @apply bg-[#e9eff3] border-2 border-blueberry text-blueberry text-sm px-8 py-2 w-60 rounded;
}

.btn-mobile-solid {
  @apply border-2 border-blueberry text-blueberry text-sm  py-3 w-full rounded-lg;
}
.btnUserTest {
  @apply bg-blueberry tracking-wide  text-white text-xl px-6 py-4 md:py-6 font-medium w-full rounded-lg shadow-md;
}
