.notification_box {
  color: #464aa6;
  background-color: #fff;
  border-radius: 1rem;
  max-width: 20rem;
  margin-top: 24px;
  padding: 10px 20px 10px 10px;
  z-index: 12;
}
.notification_box_pointer {
  position: absolute;
  color: #464aa6;
  background-color: #fff;
  border-radius: 0.3rem;
  top: 65px;
  margin-left: -10rem;
  max-width: 1rem;
  rotate: 45;
  z-index: 0;
}
.info_icon {
  float: right;
  margin-right: 15px;
}
.menu-link {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
}
.image-fixed-size {
  min-width: 2.877rem;
  min-height: 2.023rem;
}
