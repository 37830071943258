@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Medium.ttf') format('truetype'), url('../fonts/dehinted-Rubik-Medium.woff2') format('woff2'), url('../fonts/dehinted-Rubik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Light.ttf') format('truetype'), url('../fonts/dehinted-Rubik-Light.woff2') format('woff2'), url('../fonts/dehinted-Rubik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Regular.ttf') format('truetype'), url('../fonts/dehinted-Rubik-Regular.woff2') format('woff2'), url('../fonts/dehinted-Rubik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Bold.ttf') format('truetype'),url('../fonts/dehinted-Rubik-Bold.woff2') format('woff2'), url('../fonts/dehinted-Rubik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik-Black';
  src: url('../fonts/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/dehinted-Inter-Medium.woff2') format('woff2'), url('../fonts/dehinted-Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/dehinted-Inter-Bold.woff2') format('woff2'), url('../fonts/dehinted-Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/dehinted-Inter-Regular.woff2') format('woff2'), url('../fonts/dehinted-Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
