/* FloatingLoader.css */
.floating-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.floating-loader .circle {
  animation: float 1.5s ease-in-out infinite;
}

.hero-title-text {
  font-family: 'Rubik-Black' !important;
  font-weight: bolder;
  @apply lg:text-7xl sm:text-[2.125rem] text-[2.375rem]  mdSide:text-6xl md:text-6xl lg:pl-0 w-0 text-darkslateblue font-title lg:leading-[1.16] md:leading-[1.33] mdSide:leading-[1.33]  lg:tracking-widest;
}
.hero-clamp {
  font-family: 'Rubik-Black' !important;
  font-weight: bolder;
  font-size: clamp(2.125rem, 7vw, 5rem);
  @apply lg:pl-0 w-0 text-darkslateblue lg:leading-[1.16] md:leading-[1.33] mdSide:leading-[1.33]  lg:tracking-widest;
}
.home-page-info-text {
  @apply mdSide:text-[1.125rem] sm:w-fit lg:text-[1.125rem] text-[1rem] md:text-base md:text-left mdSide:text-left  lg:text-left leading-6;
}
.home-page-section-title-bold {
  @apply font-[600];
}

.home-page-place-holder-margin {
  @apply sm:pl-[7%] sm:pr-[7%] md:pl-[7%] md:pr-[7%] pl-24 pr-24 lg:pl-[8%] lg:pr-[8%]  max-w-[1920px] mx-auto;
}
.explore-page-place-holder-margin {
  @apply sm:pl-[8%] sm:pr-[8%] md:pl-[7%] md:pr-[7%] pl-24 pr-24 lg:pl-[8%] lg:pr-[8%] max-w-[1920px] mx-auto;
}

.slider {
  scrollbar-width: auto !important; /* For Firefox */
  -ms-overflow-style: auto !important; /* For Internet Explorer and Edge */
}
.topSwitch {
  width: 23.375rem;
  height: 7rem;
  position: absolute;
  border-radius: 200px;
  box-shadow: 0 0 7px 0 rgba(152, 142, 142, 0.35);
  background-color: #cdd8dd;
}

.bottomBar {
  width: 100%;
  height: 24px;
  background: #70f;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoOptionsContainer {
  border-radius: 150px;
  width: 100%;
  max-width: 24.375rem;
  height: 3.85rem;
  background-color: #fdf5ec;
  padding: 0.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.videoOptionsTab {
  width: 100%;
  height: 3rem;
  position: relative;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  line-height: 38px;
  cursor: pointer;
}

.videoOptionSelection {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.cardx {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100px;
  height: 100px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
button {
  margin: 10px;
  background-color: white;
  border: transparent;
  padding: 7px;
  height: 50px;
  width: 50px;
  border-radius: 500px;
}
.main-wrapperx {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.wrapperx {
  display: flex;
  margin: 20px;
  position: relative;
}
/* Slider.module.css */
.sliderCalculator {
  -webkit-appearance: none;
  width: 100%;
  height: 0.625rem;
  border-radius: 0.3125rem;
  background-color: #eee;
  outline: none;
  cursor: pointer;
}

.sliderCalculator::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  width: 1.313rem;
  height: 1.313rem;
  object-fit: contain;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.31);
  background-color: #7edfb5;
}

.sliderCalculator::-moz-range-thumb {
  border-radius: 50%;
  width: 1.313rem;
  height: 1.313rem;
  object-fit: contain;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.31);
  background-color: #7edfb5;
}

.Back-to-Home {
  height: 1.25rem;
  margin: 0 0 0 1.288rem;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: underline;
  @apply text-darkslateblue;
}
